<script setup lang="ts">
import {useABTesting} from "~/composables/useABTesting";

const config = useRuntimeConfig()
if (config.public.publicBaseUrl !== 'https://www.planete-croisiere.com') {
  useHead({
    meta: [
      {key: 'description', name: 'robots', content: 'noindex, nofollow'}
    ],
  })
}
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
