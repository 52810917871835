import { default as _91slug_93qZi9Vm3ICsMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/[slug].vue?macro=true";
import { default as aboutGH3bufOwudMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/about.vue?macro=true";
import { default as contactw5hEGZDkplMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/contact.vue?macro=true";
import { default as _91slug_93T6InKVbn69Meta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/cruiselines/[slug].vue?macro=true";
import { default as indexy05xSiDbfmMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/cruiselines/index.vue?macro=true";
import { default as _91slug_93bQtAXgThWHMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/cruises/[slug].vue?macro=true";
import { default as _91slug_93pgZP4dJkkDMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/departure-port/[slug].vue?macro=true";
import { default as indexHnGDKvMfv5Meta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/departure-port/index.vue?macro=true";
import { default as _91slug_93IzItXqmyk4Meta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/destinations/[slug].vue?macro=true";
import { default as indexE6TXiYA0O1Meta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/destinations/index.vue?macro=true";
import { default as indexRrZhLV1bbEMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/faq/index.vue?macro=true";
import { default as indexmyJxP6YDPoMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/index.vue?macro=true";
import { default as indexAmG2Ia6skLMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/search/index.vue?macro=true";
import { default as _91slug_93GnKPObgyOhMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/ships/[slug].vue?macro=true";
import { default as indexwNhFYCr5RGMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/ships/index.vue?macro=true";
import { default as sitemapV3PbIwLLZsMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/sitemap.vue?macro=true";
import { default as style_45guidesAPNooeDqcMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/pages/style-guide.vue?macro=true";
import { default as component_45stubOsLRqi8tHtMeta } from "/var/www/planete-croisiere/prod/atrium/releases/132/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubOsLRqi8tHt } from "/var/www/planete-croisiere/prod/atrium/releases/132/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___fr",
    path: "/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/[slug].vue")
  },
  {
    name: "about___fr",
    path: "/infos-utiles",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/about.vue")
  },
  {
    name: "contact___fr",
    path: "/contact",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/contact.vue")
  },
  {
    name: "cruiselines-slug___fr",
    path: "/compagnie/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/cruiselines/[slug].vue")
  },
  {
    name: "cruiselines___fr",
    path: "/compagnie",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/cruiselines/index.vue")
  },
  {
    name: "cruises-slug___fr",
    path: "/croisiere/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/cruises/[slug].vue")
  },
  {
    name: "departure-port-slug___fr",
    path: "/ports-de-depart/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/departure-port/[slug].vue")
  },
  {
    name: "departure-port___fr",
    path: "/ports-de-depart",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/departure-port/index.vue")
  },
  {
    name: "destinations-slug___fr",
    path: "/destination/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/destinations/[slug].vue")
  },
  {
    name: "destinations___fr",
    path: "/destination",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/destinations/index.vue")
  },
  {
    name: "faq___fr",
    path: "/faq",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/faq/index.vue")
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/index.vue")
  },
  {
    name: "search___fr",
    path: "/recherche",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/search/index.vue")
  },
  {
    name: "ships-slug___fr",
    path: "/navire/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/ships/[slug].vue")
  },
  {
    name: "ships___fr",
    path: "/navire",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/ships/index.vue")
  },
  {
    name: "sitemap___fr",
    path: "/plan-du-site",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/sitemap.vue")
  },
  {
    name: "style-guide___fr",
    path: "/style-guide",
    meta: style_45guidesAPNooeDqcMeta || {},
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/132/pages/style-guide.vue")
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/themes/croisieres-en-norvege/pourquoi-choisir-la-croisiere-en-scandinavie",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/themes/croisieres-de-luxe",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/themes/croisieres-en-norvege/comment-organiser-au-mieux-sa-croisiere-en-norvege",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/themes/croisieres-en-norvege/quels-sont-les-avantages-dune-croisiere-en-petit-bateau-dans-les-fjords-norvegiens",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/themes/reprise-croisiere-protocoles-sanitaires",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/navire/costa-magica",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/costa-magica/navire",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/navire/croisiere-explora-i",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisiere-explora-i/navire",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/compagnie/croisieres-maritimes-et-voyages",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisieres-maritimes-et-voyages/compagnie",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/compagnie/croisimer",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisimer/compagnie",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/compagnie/paul-gauguin-cruises",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/paul-gauguin-cruises/compagnie",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/navire/costa-firenze",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/costa-firenze/navire",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/navire/costa-luminosa",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/costa-luminosa/navire",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/infos-utiles/abonnement-newsletter",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/navire/ocean-diamond",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/ocean-diamond/navire",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/navire/msc-europa",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/msc-europa/navire",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/navire/ms-lofoten",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/ms-lofoten/navire",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/navire/insigna",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/insigna/navire",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisiere/tour-du-spitzberg-au-royaume-de-l-ours-polaire-1",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/tour-du-spitzberg-au-royaume-de-l-ours-polaire-1/croisiere",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisiere/aventure-arctique-7",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/aventure-arctique-7/croisiere",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisiere/expedition-autour-du-spitzberg-1",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/expedition-autour-du-spitzberg-1/croisiere",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisiere/bergen-kirkenes-bergen-avec-accompagnateur-1",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/bergen-kirkenes-bergen-avec-accompagnateur-1/croisiere",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisiere/aventure-arctique",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/aventure-arctique/croisiere",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisiere/aventure-arctique-6",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/aventure-arctique-6/croisiere",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/navire/celebrity-beyond-2",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/celebrity-beyond-2/navire",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisiere/costa-fortuna-martinique-guadeloupe-republique-dominicaine-iles-vierges-britanniques-saint-martin-4",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/costa-fortuna-martinique-guadeloupe-republique-dominicaine-iles-vierges-britanniques-saint-martin-4/croisiere",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisiere/costa-luminosa-italie-france-espagne-malte-grece-9",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/costa-luminosa-italie-france-espagne-malte-grece-9/croisiere",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/croisiere/rois-empereurs",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/rois-empereurs/croisiere",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/destination/polaire-spitzberg",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/polaire-spitzberg/destination",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/infos-utiles/l-equipe-planete-croisiere",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/infos-utiles/bien-choisir-ma-cabine",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/infos-utiles/questions-frequentes",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/lp/offre-exclusive-credit-bord-msc",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/lp/offre-speciale-msc-lirica-venise-santorin-et-mykonos",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/demandes-informations",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/ponant/navire",
    component: component_45stubOsLRqi8tHt
  },
  {
    name: component_45stubOsLRqi8tHtMeta?.name,
    path: "/qui-sommes-nous",
    component: component_45stubOsLRqi8tHt
  }
]